import type { ReportInputType } from "@/model/Enums";
import { mapData } from "@/utilities/DataMapping";
import DateUtils from "@/utilities/DateUtils";
import Utils from "@/utilities/Utils";
import { ILookupItem, LookupItem } from "@/model/LookupItem";

export interface IReportParameter {
    reportID: string;
    parameter: string;
    type: ReportInputType;
    label: string;
    required: boolean;
    datePickerValue: Date;
    dropdownValue: string;
    textValue: string;
    dropdownOptions: Array<ILookupItem>;
}

export class ReportParameter implements IReportParameter {
    
    constructor(data?: IReportParameter) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IReportParameter): ReportParameter {
        mapData(data, { 
            root: () => this,
            dropdownOptions: (data: ILookupItem) => new LookupItem(data)
        });
        return this;
    }

    reportID: string = Utils.emptyGuidValue;
    parameter: string = "";
    type: ReportInputType = 0;
    label: string = "";
    required: boolean = false;
    datePickerValue: Date = DateUtils.emptyDate();
    dropdownValue: string = Utils.emptyGuidValue;
    textValue: string = "";
    dropdownOptions: Array<LookupItem> = [];

}