














































    import Vue from "vue";
    import Component from "vue-class-component";
    import ApiButton from "@/vue/components/ApiButton.vue";
    import ApiClient from "@/utilities/ApiClient";
    import DatePicker from "@/vue/components/DatePicker.vue"
    import DateUtils from "@/utilities/DateUtils";
    import fileDownload from "@/utilities/FileDownload";
    import { Ref, Watch } from "vue-property-decorator";
    import { VForm } from "@/vForm";
    import { ReportInputType } from "@/model/Enums";
    import { ILookupItem, LookupItem } from "@/model/LookupItem";
    import { IReportParameter, ReportParameter } from "@/model/ReportParameter";
    import * as toastr from "toastr";

    @Component({
        components: { 
            ApiButton,
            DatePicker
        }
    })

    export default class Reports extends Vue {

        async mounted(): Promise<void> { 
            const response = await ApiClient.get("api/report/reports");
            this.reports.push(...response.map((i: ILookupItem) => new LookupItem(i)));
        }

    //
    // -- properties
    //
    
    @Ref("parametersForm") private readonly parametersForm!: VForm;

    private reports: Array<LookupItem> = [];
    private reportID: number = 0;
    private parameters: Array<ReportParameter> = [];
    private reportData: Array<any> = [];

    //
    // - watchers
    //

    @Watch("reportID")
    private async reportIDChanged() {
        this.reportData = [];
        this.getParameters()
    }

    //
    // -- methods
    //

    async getParameters(): Promise<void> {
        if (this.reportID == 0) {
            this.parameters = [];
            return;
        }

        const response = await ApiClient.get(`api/report/${this.reportID}/parameters`);
        this.parameters.push(...response.map((p: IReportParameter) => new ReportParameter(p)));
    }
 
    validationRules(parameter: ReportParameter)  {
        if (!parameter.required) return [];
        
        if (parameter.type == ReportInputType.DatePicker) 
            return [(_v:Date) => DateUtils.hasDateValue(parameter.datePickerValue)||""]

        return [(v:any) => !!v||""];
    }

    private async runReport (isExport: boolean) {
        const isValid = await this.parametersForm.validate();
        if (!isValid) {
            toastr.warning("Please fix highlighted issues", "Cannot Run Report");
            return;
        }
        
        if (isExport) {
            const blob: Blob = await ApiClient.post(`api/report/${this.reportID}/export`, this.parameters, "report-running");
            fileDownload.downloadBlob(document, blob, "report.csv");
        }
        else {
            this.reportData = [];

            const response = await ApiClient.post(`api/report/${this.reportID}/run`, this.parameters, "report-running");
            this.reportData.push(...response);
        }
    }
}

