module DateUtils { 

    // regexes match:
    //    "yyyy-MM-dd HH:mm[anything]"
    //    "yyyy-MM-ddTHH:mm[anything]"
    //    "yyyy-MM-dd"
    // ...which should be enough to identify an ISO date string
    const isoDateTimeRegex = /^\d{4}-[01]\d-[0-3]\d[T ][0-2]\d:[0-5]\d/;
    const isoDateOnlyRegex = /^\d{4}-[01]\d-[0-3]\d$/;
    const emptyDateValue = -62135596800000;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const millisInAMinute = 1000*60;
    const millisInAnHour = 1000*60*60;
    const millisInADay = 1000*60*60*24;

    export const parse = (value: any, isIsoOnly: boolean = false): Date|null => {
        if(typeof value === "undefined" || value === null) {
            return null;
        }

        if(typeof value.getMonth === "function" && !isNaN(value.getMonth())) {
            // we have an actual date object!
            return value;
        }

        if(typeof value === "string" && isIsoOnly) {
            if(isApiDateString(value)) {
               return new Date(value); 
            }
            return null;
        }

        if(typeof value === "string") {
            // try to parse any old string
            const millis = Date.parse(value);
            if(!isNaN(millis)) {
                return new Date(millis); 
            }
            return null;
        }

        if(typeof value === "number") {
            return new Date(value);
        }
 
        return null;
    }

    export const displayDate = (value: any, locale: string = ""): string => {
        const dateValue = parse(value);
        if (dateValue === null || !hasDateValue(dateValue)) return "";

        const monthIndex = dateValue.getMonth();
        if(monthIndex < 0 || monthIndex > 11) return "";

        return `${pad2(dateValue.getDate())} ${monthNames[monthIndex].substring(0, 3)} ${dateValue.getFullYear()}`;
    }

    export const displayTime = (value: any): string => {
        const dateValue = parse(value);
        if (dateValue === null || !hasDateValue(dateValue)) return "";
        return `${pad2(dateValue.getHours())}:${pad2(dateValue.getMinutes())}`;
    }
    
    export const displayDateTime = (value: any): string => {
        const dateValue = parse(value);
        if (dateValue === null || !hasDateValue(dateValue)) return "";
        return `${displayDate(dateValue)} ${displayTime(dateValue)}`;
    }

    export const isoDate = (value: any): string => {
        const dateValue = parse(value);
        if (dateValue === null || !hasDateValue(dateValue)) return "";
        const monthIndex = dateValue.getMonth();
        if(monthIndex < 0 || monthIndex > 11) return "";
        return `${dateValue.getFullYear()}-${pad2(dateValue.getMonth() + 1)}-${pad2(dateValue.getDate())}`;
    }

    export const isoDateTime = (value: any): string => {
        const dateValue = parse(value);
        if (dateValue === null || !hasDateValue(dateValue)) return "";
        return `${isoDate(dateValue)}T${pad2(dateValue.getHours())}:${pad2(dateValue.getMinutes())}:${pad2(dateValue.getSeconds())}`;
    }

    export const whenText = (value: any): string => {
        const dateValue = parse(value);
        if (dateValue === null || !hasDateValue(dateValue)) return "";
        const now = new Date();
        const daysDiff = diffDays(now, dateValue);
        if (daysDiff < 0) { return displayDate(dateValue); } // future
        if (daysDiff === 1) { return "yesterday"; }
        if (daysDiff > 7) { return displayDate(dateValue); } // more than a few days ago
        if (daysDiff > 1) { return `${daysDiff} days ago`; }
        const hoursDiff = diffHours(now, dateValue);
        if (hoursDiff === 1) { return "an hour ago"; }
        if (hoursDiff > 1) { return `${hoursDiff} hours ago`; }
        const minsDiff = diffMinutes(now, dateValue);
        if (minsDiff === 1) { return "a minute ago"; }
        if (minsDiff > 1) { return `${minsDiff} mins ago`; }
        return "just now";
    }

    ///////
    
    export const emptyDate = (): Date => {
        return new Date(emptyDateValue);
    }

    export const isDate = (d: any): boolean => {
        if (!d) return false;
        return typeof d.getMonth === "function" && !isNaN(d.getMonth());
    }

    export const hasDateValue = (d: any): boolean => {
        return isDate(d) && (+(d)) > -6847804800000; // 1753 = min SQL date
    }

    export const isApiDateString = (text: any): boolean => 
        typeof text === "string" 
        && (isoDateOnlyRegex.test(text) 
        || isoDateTimeRegex.test(text));

    export const removeTime = (value: Date) => new Date(value.toDateString());

    export const addDays = (value: Date, days: number) => {
        const date = new Date(value.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    };

    // ignores time component
    export const diffDays = (value1: Date, value2: Date): number => {
        const diffMillis = removeTime(value1).valueOf() - removeTime(value2).valueOf();
        return Math.trunc(diffMillis / millisInADay);
    };

    export const diffHours = (value1: Date, value2: Date): number => {
        const diffMillis = value1.valueOf() - value2.valueOf();
        return Math.trunc(diffMillis / millisInAnHour);
    };

    export const diffMinutes = (value1: Date, value2: Date): number => {
        const diffMillis = value1.valueOf() - value2.valueOf();
        return Math.trunc(diffMillis / millisInAMinute);
    };

    const pad = (padWith: string, value: number, isPadLeft: boolean = true): string => {
        const text = String(value);
        return isPadLeft
            ? (padWith + text).slice(-padWith.length)
            : (text + padWith).substring(0, padWith.length);
    }

    const pad2 = (x: number): string => pad("00", x);
}

export default DateUtils;